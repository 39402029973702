import React, {createContext, useState, useEffect} from "react";
import enums from '../enums.json';
import {language} from "../shared-components/content/language";
import {Utility} from "../shared-components/Utility";

const {languages} = enums;

export const GeneralContext = createContext(null);

export const GeneralProvider = (props) => {
    const [contentLoading, setContentLoading] = useState(true);
    const [appLang, setAppLang] = useState(languages.ENGLISH);
    const content = language[appLang];
    // const [langDir, setLangDir] = useState("ltr");   //save to use if needed for rtl languages

    const [uploadStage, setUploadStage] = useState(1);
    const [returnToStage, setReturnToStage] = useState(1);
    const [currentUploads, setCurrentUploads] = useState([]);
    const [hasAcceptedTerms, setHasAcceptedTerms] = useState(true);
    const [userEmail, setUserEmail] = useState("");
    const [userHandle, setUserHandle] = useState("");
    const [brandIsDoingCgc, setBrandIsDoingCgc] = useState(true);
    const [name, setName] = useState("");
    const [brandId, setBrandId] = useState("");
    const [handle, setHandle] = useState("");
    const [googleLink, setGoogleLink] = useState("");
    const [incentiveStatement, setIncentiveStatement] = useState(content.GET_DISCOUNT_SENTENCE);
    const [secondIncentive, setSecondIncentive] = useState("");
    const [industryCode, setIndustryCode] = useState(0);
    const [imagesProcessedId, setImagesProcessedId] = useState("");
    const [submitInitialized, setSubmitInitialized] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [hasBeenAskedToShareVideo, setHasBeenAskedToShareVideo] = useState(false);
    const [imagesRejectedNotificationOpen, setImagesRejectedNotificationOpen] = useState(false);
    const [sessionStartDts, setSessionStartDts] = useState(new Date());
    const [thankYouDts, setThankYouDts] = useState(new Date());
    const [lastSessionUpdate, setLastSessionUpdate] = useState(new Date());
    const [howHeardAboutUsResponse, setHowHeardAboutUsResponse] = useState("");

    const updateSession = () => setLastSessionUpdate(new Date());
    const trackerTicker = setInterval(updateSession, 5000);

    useEffect(() => {
        return () => {
            clearInterval(trackerTicker);
            setSessionStartDts(new Date());
        }
    }, []);

    useEffect(() => {
        if (new Date(sessionStartDts).valueOf() + 60 * 60 * 1000 < new Date(lastSessionUpdate).valueOf()) {
            setReturnToStage(1);
            setUploadStage(1);
            setSessionStartDts(new Date());
        }
    }, [lastSessionUpdate]);

    useEffect(() => {
        if (submitInitialized && !!imagesProcessedId) {
            updateCgcUserData().catch((e) => console.error(e));
        }
    }, [submitInitialized, imagesProcessedId]);

    const switchLanguage = language => {
        setAppLang(language);
        // setLangDir(language === languages.HEBREW || language === languages.ARABIC ? "rtl" : "ltr")   //save to use if needed for rtl languages
    };


    const uploadMedia = async (files) => {
        const newUploads = [];
        const convertToBase64Promises = [];

        files.forEach((file) => {
            if (file.size > 25000000) {
                setErrorMsg(Utility.capitalize(content.FILE_TOO_LARGE))
                return;
            }

            const metaData = {
                type: file.type,
                size: file.size,
                fileExt: file.name.split(".").pop(),
            }
            const tempUrl = URL.createObjectURL(file);

            const tempUpload = {
                metaData,
                tempUrl
            }

            convertToBase64Promises.push(Utility.fileToBase64(file));
            newUploads.push(tempUpload);
        });

        const arrayBufferResults = await Promise.all(convertToBase64Promises);

        setCurrentUploads([...currentUploads, ...newUploads.map((updatedUpload, index) => {
            return {
                ...updatedUpload,
                fileDataBase64: arrayBufferResults[index]
            }
        })]);
    }

    const uploadBlob = async (blob, fileExt) => {
        if (blob.size > 25000000) {
            setErrorMsg(Utility.capitalize(content.FILE_TOO_LARGE))
            return;
        }

        const metaData = {
            type: blob.type,
            size: blob.size,
            fileExt,
        }
        const tempUrl = URL.createObjectURL(blob);

        const arrayBufferResult = await Utility.fileToBase64(blob);

        const tempUpload = {
            metaData,
            tempUrl,
            fileDataBase64: arrayBufferResult,
        }

        setCurrentUploads([...currentUploads, tempUpload]);
    }

    const checkUploadsIncludeVideos = () => {
        let uploadsIncludeVideos = false;
        currentUploads.forEach((upload) => {
            if (upload?.metaData?.type?.startsWith('video/')) {
                uploadsIncludeVideos = true;
            }
        });

        return uploadsIncludeVideos;
    }

    const clearTempMedia = () => {
        const updatedUploads = [];
        currentUploads.forEach((upload) => {
            if (!!upload.tempUrl) {
                URL.revokeObjectURL(upload.tempUrl);
            } else updatedUploads.push(upload);
        });

        setCurrentUploads(updatedUploads);
    }

    const saveAndFilterImages = async () => {
        let success;

        const newMediaData = [];
        const alreadyAcceptedMedia = [];
        currentUploads.forEach((currentUpload) => {
            if (!!currentUpload.imageId) {
                alreadyAcceptedMedia.push(currentUpload);
            } else if (!!currentUpload.fileDataBase64) {
                newMediaData.push(currentUpload);
            }
        });


        if (newMediaData.length === 0) {
            if (alreadyAcceptedMedia.length === 0) {
                setErrorMsg(content.IMAGE_UPLOAD_ERROR_SENTENCE);
            }
            return;
        }

        setImagesProcessedId("");

        try {
            const response = await Utility.httpCall("submitCgc", {
                brandId,
                brandName: name,
                newMediaData,
                industryCode
            });

            if (!response?.data?.mediaProcessedId) {
                success = false;
            } else {
                setImagesProcessedId(response.data.mediaProcessedId);
                setCurrentUploads(response.data.successfullyLoadedAndFilteredMedia || []);
                success = true;
            }
        } catch (e) {
            console.error(e);
            success = false;
        }

        if (!success) {
            setSubmitInitialized(false);
            setContentLoading(false);
            setErrorMsg(content.THERE_WAS_A_PROBLEM_UPLOADING_YOUR_CONTENT_SENTENCE);
        }
    }

    const acceptTermsAndConditions = () => !(!hasAcceptedTerms || currentUploads.length < 1);

    const goToShareMore = () => {
        const readyToGoToNext = acceptTermsAndConditions();

        if (readyToGoToNext) {
            setUploadStage(3);
            setReturnToStage(2);
            saveAndFilterImages().then();
        }
    }

    const goBackToKeepSnapping = () => {
        setUploadStage(2);
        setReturnToStage(1);
    }

    const goToViewMyShares = () => {
        setReturnToStage(4);
        setUploadStage(5);
    }

    const handleSubmitData = async () => {
        setContentLoading(true);
        setSubmitInitialized(true);
    }

    const updateCgcUserData = async () => {
        let success;

        if ((!userEmail && !userHandle) || imagesProcessedId === "allImagesFailed") {
            success = true;
        } else {
            try {
                const response = await Utility.httpCall("updateCgcUserData", {
                    brandId,
                    mediaProcessedId: imagesProcessedId,
                    userEmail,
                    userHandle,
                    howHeardAboutUsResponse,
                });

                success = response?.data;
            } catch (e) {
                console.error(e);
                success = false;
            }
        }

        setContentLoading(false);
        setSubmitInitialized(false);

        if (!!success) {
            if (imagesProcessedId === "allImagesFailed") {
                setImagesRejectedNotificationOpen(true);
            } else {
                setReturnToStage(3);
                setUploadStage(4);
            }
        } else {
            setErrorMsg(Utility.capitalize(content.THERE_WAS_A_PROBLEM_UPLOADING_YOUR_CONTENT_SENTENCE));
        }
    }

    const deleteImage = async (imageId, filePath) => {
        try {
            const response = await Utility.httpCall("deleteMedia", {imageId, filePath});
            if (!response.data) {
                console.error(`Error deleting ${imageId}`);
            }
        } catch (e) {
            console.error(e);
        }
    }

    const getBrandData = async (id) => {
        if (!id) {
            setBrandIsDoingCgc(false);
            return
        }

        const response = await Utility.httpCall("getBrandProps", {
            brandId: id,
            props: [
                "isCgcEnabled",
                "brandName",
                "brandHandle",
                "googleReviewLink",
                "cgcIncentive",
                "secondIncentive",
                "industryCode",
            ]
        });
        const {
            isCgcEnabled,
            brandName,
            brandHandle,
            googleReviewLink,
            cgcIncentive,
            secondIncentive,
            industryCode
        } = response.data;

        setBrandIsDoingCgc(!!isCgcEnabled);
        setName(brandName);
        setHandle(brandHandle);
        setGoogleLink(googleReviewLink || "");
        setIncentiveStatement(cgcIncentive || content.GET_DISCOUNT_SENTENCE);
        setSecondIncentive(secondIncentive);
        setBrandId(id);
        setIndustryCode(industryCode || 0);
    }

    return (
        <GeneralContext.Provider value={{
            contentLoading,
            appLang,
            uploadStage,
            returnToStage,
            currentUploads,
            hasAcceptedTerms,
            brandId,
            userEmail,
            userHandle,
            brandIsDoingCgc,
            handle,
            googleLink,
            incentiveStatement,
            secondIncentive,
            industryCode,
            submitInitialized,
            errorMsg,
            hasBeenAskedToShareVideo,
            imagesRejectedNotificationOpen,
            thankYouDts,
            howHeardAboutUsResponse,

            setContentLoading,
            setAppLang,
            setUploadStage,
            setReturnToStage,
            setCurrentUploads,
            setHasAcceptedTerms,
            setUserEmail,
            setUserHandle,
            setSubmitInitialized,
            setErrorMsg,
            setHasBeenAskedToShareVideo,
            setImagesRejectedNotificationOpen,
            setThankYouDts,
            setHowHeardAboutUsResponse,

            switchLanguage,
            uploadMedia,
            uploadBlob,
            clearTempMedia,
            goToShareMore,
            goBackToKeepSnapping,
            getBrandData,
            deleteImage,
            checkUploadsIncludeVideos,
            goToViewMyShares,
            handleSubmitData,
        }}>
            {props.children}
        </GeneralContext.Provider>
    )
}
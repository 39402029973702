import React, {useContext} from 'react';
import {GeneralContext} from "../contexts/GeneralContext";
import {language} from "../shared-components/content/language";
import {Utility} from "../shared-components/Utility";
import cgcCoverVideoFnB from "../assets/cgc-cover-video-fnb.mp4";
import cgcCoverAlternate from "../assets/cgc-cover-alternate.jpg";
import StyledList from "../shared-components/generic/StyledList";
import StyledButton from "../shared-components/generic/StyledButton";
import {ModalContext} from "../contexts/ModalContext";

const LandingPage = () => {
    const {
        appLang,
        brandId,
        incentiveStatement,
        secondIncentive,
        industryCode,
    } = useContext(GeneralContext);
    const {setUploadMediaModalOpen, setFromLandingPage} = useContext(ModalContext)
    const content = language[appLang];

    const items = [
        Utility.capitalize(content[!industryCode ? "SNAP_AND_UPLOAD_SENTENCE" : "SNAP_AND_UPLOAD_SENTENCE_ALT"]),
        Utility.capitalize(incentiveStatement),
        Utility.capitalize(content.WELL_SPREAD_LOVE_SENTENCE)
    ];
    if (!!secondIncentive) {
        items.push(Utility.capitalize(secondIncentive));
    }

    const handleInitiateUpload = () => {
        setUploadMediaModalOpen(true);
        setFromLandingPage(true);
    }

    return (
        <div className="landing-page">
            {!!brandId
                ? <>
                    <div className="cgc-cover-container">
                        {Utility.renderMedia(
                            !industryCode ? cgcCoverVideoFnB : cgcCoverAlternate,
                            content.IMAGE_OF_SOMEONE_TAKING_A_PICTURE_OF_FOOD,
                            null,
                            "cgc-cover"
                        )}
                    </div>
                    <div className="main-container">
                        <div className="big-text">
                            <span>{Utility.capitalize(content[!industryCode ? "LOVE_OUR_FOOD" : "LOVE_OUR_EXPERIENCE"])}?</span>
                            <span>{Utility.capitalize(content.SHARE_THE_JOY)}!</span>
                        </div>
                        <StyledList
                            className="small-text"
                            numbered
                            items={items}
                        />
                        <div className="bottom-section">
                            <StyledButton
                                type="main"
                                onClick={handleInitiateUpload}
                            >
                                {Utility.capitalize(content.GET_STARTED)}
                            </StyledButton>
                        </div>
                    </div>
                </>
                : null
            }
        </div>
    );
};

export default LandingPage;